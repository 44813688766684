<template>
  <!-- 采购计划管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table :operation-button-width="260" :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { agentPurchasePlanPage } from '@/api/generationMining'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        supplierCmpName: '',
        fundsCmpName: '',
        planStatus: ''
      },
      formItemArr: [
        { type: 'input', label: '合作企业', value: 'supplierCmpName' },
        { type: 'input', label: '经销商', value: 'custCmpName', width: '120' },
        { type: 'input', label: '代采机构', value: 'fundsCmpName', width: '120' },
        { type: 'select', label: '状态', pLabel: 'dictName', pValue: 'dictId', value: 'planStatus', width: '120', child: this.$store.getters.getDictionaryItem('planStatus') }
      ],
      itemData: [
        { label: '采购计划单号', prop: 'planNo', width: 150 },
        { label: '经销商', prop: 'custCmpName', width: 180 },
        { label: '合作企业', prop: 'supplierCmpName', width: 180 },
        { label: '代采机构', prop: 'fundsCmpName', width: 180 },
        { label: '货款金额', prop: 'totalPayment', width: 120, type: 'money' },
        { label: '保证金比例(%)', prop: 'depositRate', width: 120, unit: '%' },
        // { label: '代采费率(%)', prop: 'agentRate', width: 100 },
        { label: '结算日期', prop: 'settleDate', width: 120 },
        { label: '状态', prop: 'planStatus', width: 120, child: this.$store.getters.getDictionaryItem('planStatus') },
        { label: '创建时间', prop: 'createTime', width: 140 }
      ],
      listData: [],
      operationButton: [
        // { num: 1, val: 'planStatus', bType: 'primary', label: '保证金支付', handleEvent: this.marginPayment },
        // { num: 1, val: 'planStatus', bType: 'primary', label: '取消', handleEvent: this.cancelTable },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0,
      paymentObj: {},
      agreementConfig: {
        visible: false,
        data: {}
      },
      paymentButtonLoading: false
    }
  },
  methods: {
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      agentPurchasePlanPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.settleDate = item.settleDate + '号'
        })
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable({ id }) {
      this.$router.push({ path: '/purchasePlanManage/purchasePlanInfo', query: { id }})
    }
  }
}
</script>
